import { Application } from "@hotwired/stimulus"
import consumer from '../channels/consumer'

const mode = process.env.NODE_ENV || 'development'
const application = window.Stimulus || Application.start()

// Configure Stimulus development experience
application.debug = document.documentElement.classList.contains("debug")
application.consumer = consumer

window.Stimulus = application

export { application }

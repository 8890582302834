export default (dialog = undefined) => {
  const requestButton = document.getElementById('termsModal')
  if (!requestButton) return false

  const notExistCaller = document.getElementById('not_exist_call').children[0]

  requestButton.addEventListener('click', () => {
    if (dialog) dialog.hide()

    setTimeout(() => notExistCaller.click(), 500)
  })
}
